* {
  user-select: none;
}

body {
  background: #000;
  color: #FFF;
  overflow: hidden;
}

.game {
  padding: 5vmin;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-columns: 90vmin 1fr;
  grid-template-rows: 90vmin 1fr;
  grid-template-areas: "GB SB"
                       "X SB";
}

@media screen and (orientation:portrait) {
  .game {
    grid-template-areas: "GB X"
                         "SB SB";
  }
}

@media screen and (min-height: 2000px) {
  body {
    font-size: 32px;
  }
}


.fields {
  grid-area: GB;
  background-color: #FFF;
  background-image: url('./board.svg');
  background-size: cover;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "NW NE" "SW SE";
  gap: 7.5%;
}

.clickable:hover {
  filter: drop-shadow(0 0 4px rgba(255, 255, 0, 0.75));
}

.chosen {
  filter: drop-shadow(0 0 4px rgba(0, 255, 0, 0.75));
}

.field {
  position: relative;
  text-align: center;
  padding: 0;
}

.field:not(.clickable) {
  pointer-events: none;
}

.field:not(.clickable) * {
  pointer-events: painted;
}

.field.clickable:hover {
  box-shadow: none;
  filter: none;
}

.field>.sowbutton {
  transition-duration: 0.125s;
  transition-property: background-color, box-shadow;
  z-index: 9999;
  width: 16ex;
  margin-left: calc(50% - 8ex);
  margin-bottom: -8px;
  border-radius: 1em;
  padding: 0.5em 1em;
  text-align: center;
  color: rgba(255,255,255, 0.33);
  background: rgba(0, 0, 0, 0.5);
  /* backdrop-filter: blur(8px); */
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  /* will-change: backdrop-filter, box-shadow; */
}

.field>.sowbutton:hover {
  transition-duration: 0.125s;
  transition-property:  background-color, box-shadow, color;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 6px 6px rgba(50, 50, 50, .75);
  color: #FFF;
}

.field-NW { grid-area: NW; transform: rotate(135deg); }
.field-NE { grid-area: NE; transform: rotate(225deg); }
.field-SE { grid-area: SE; transform: rotate(315deg); }
.field-SW { grid-area: SW; transform: rotate(45deg); }

.fieldcards {
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.field-NE>.fieldcards>.cardlike,
.field-NW>.fieldcards>.cardlike,
.field-NE>.luminary,
.field-NW>.luminary,
.field-NE>.sowbutton,
.field-NW>.sowbutton {
  transform: rotate(180deg);
}

body {
  --card-height: 7em;
  --card-width: calc((var(--card-height) * 410) / 578);
}

.card {
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-property: top, box-shadow, width, height, left, right, margin, background-size, background-position;
  z-index: 1;
  color: #000;
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  padding: 0 0;
  box-sizing: border-box;
  background: #fef5e6;
  border-radius: 0.75em;
  font-family: 'Segoe UI Emoji';
  font-weight: 700;
  font-size: 1em;
  filter: none;
  border: solid 0px #000;
  text-align: center;
  vertical-align: middle;
  margin: 3px;
  background-size: cover;
  top: 0;
  contain: style;
}

.cardlike.clickable {
  cursor: pointer;
}

.cardlike.clickable:hover, .cardlike.chosen {
  filter: none;
}
.cardlike.clickable:hover .card,
.card.clickable:hover {
  box-shadow: 0 0 4px 4px rgba(255, 255, 0, 0.75);
}

.cardlike.chosen .card,
.card.chosen {
  box-shadow: 0 0 4px 4px rgba(0, 255, 0, 0.75);
  cursor: pointer;
}
.cardstack-reg>.card:not(:last-of-type) {
  color: #000;
  display: block;
  overflow: hidden;
  width: var(--card-width);
  padding: 0;
  margin-bottom: calc(0px - (0.85 * var(--card-height)));
}

.cardstack-reg>.card:not(:last-of-type):hover {
  top: -1em;
}

.cardstack-multi {
  display: inline-flex;
  position: relative;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  min-width: var(--card-height);
  margin-bottom: 0.5em;
}

.cardstack-reg {
  margin: 0 3px;
}

.cardstack-reg>.card {
  margin: 0;
  margin-left: calc(1 * calc(7px * var(--stack-index, 0)));
  z-index: calc(var(--stack-index, 0) + 1);
}

.cardstack>.card {
  position: relative;
}

/* Max multi stack size is 4 */ 
.cardstack-multi>.cardlike:nth-child(2) { --multi-index: 1; }
.cardstack-multi>.cardlike:nth-child(3) { --multi-index: 2; }
.cardstack-multi>.cardlike:nth-child(4) { --multi-index: 3; }

/* Max stack size is 9 (1+1+1+1+2+2+2+2+2 = 14)*/ 
.cardstack-reg>.card:nth-child(2) { --stack-index: 1; }
.cardstack-reg>.card:nth-child(3) { --stack-index: 2; }
.cardstack-reg>.card:nth-child(4) { --stack-index: 3; }
.cardstack-reg>.card:nth-child(5) { --stack-index: 4; }
.cardstack-reg>.card:nth-child(6) { --stack-index: 5; }
.cardstack-reg>.card:nth-child(7) { --stack-index: 6; }
.cardstack-reg>.card:nth-child(8) { --stack-index: 7; }
.cardstack-reg>.card:nth-child(9) { --stack-index: 8; }

.cardstack-multi>.card:last-of-type {
  color: #000;
  display: block;
  overflow: hidden;
  transform: rotate(90deg);
  transform-origin: 100% 100%;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0.5em;
  /* right: calc(calc(var(--multi-index, 0) + 1) * (0.5 * var(--card-width)) + var(--card-height) - 1em); */
  right: calc((var(--multi-index, 0) + 1) * 3px + 50% + 1.5 * var(--card-width));
  margin-right: calc(-4px - var(--card-width));
  z-index: 10;
}

.cardstack-multi>.card {
  margin: 3px 0;
}

.cardstack-multi>.cardstack-reg {
  width: var(--card-width);
  margin: 0 calc((var(--stack-size, 1) - 1) * 7px) 0 0;
}
.cardstack-multi>.cardstack-reg:not(:first-child) {
  margin-left: calc((1 - var(--stack-size, 1)) * 7px);
}

.field .card:hover, .field .cardlike:hover, .field .cardlike:hover .cardlike, .field .cardlike:hover .card {
  --card-height: 14em;
  --card-width: calc((var(--card-height) * 410) / 578);
  transition-duration: 0.5s, 0.5s, 0.75s, 0.75s, 0.75s, 0.75s, 0.75s, 0.75s, 0.75s, 0.75s;
  transition-delay: 0s, 0s, 1.5s, 1.5s, 1.5s, 1.5s, 1.5s, 1.5s, 1.5s, 1.5s;
  transition-property: top, box-shadow, width, height, left, right, margin, background-size, background-position, min-width;
}

.illimat {
  font-family: 'Segoe UI Emoji';
  transition-duration: 0.8s;
  transition-property: transform;
  position: absolute;
  top: calc(40% - 1.25em - 0.75vmin);
  left: calc(40% - 1.25em + 0.125vmin);
  background-image: url('./illimat.jpg');
  background-size: cover;
  width: calc(20% + 2.5em);
  height: calc(20% + 2.5em);
  transform: rotate(var(--illimat-rotate));
  text-align: center;
  box-sizing: border-box;
  grid-area: GB;
}

.illimat.rotate-0 { --illimat-rotate: 45deg; }
.illimat.rotate-1 { --illimat-rotate: 135deg; }
.illimat.rotate-2 { --illimat-rotate: 225deg; }
.illimat.rotate-3 { --illimat-rotate: 315deg; }
.illimat .remaining-okus {
  display: flex;
  align-content: space-evenly;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.illimat .okus {
  display: block;
  transform: rotate(calc(0deg - var(--illimat-rotate)));
  font-size: calc(3vmin + 1em);
  min-width: 34%;
}

.sidebar {
  position: relative;
  grid-area: SB;
}

.luminary {
  --luminary-size: calc(5vmin + 3em);
  height: calc(1.715542521994 * var(--luminary-size));
  width: var(--luminary-size);
  background: url('./luminaryback.webp');
  background-size: cover;
  bottom: 0;
  left: calc(50% - (0.5 * var(--luminary-size)));
  position: absolute;
}

.context-options-wrap {
  grid-area: GB;
  position: absolute;
  display: flex;
  align-content: space-evenly;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 50%;
  flex-wrap: wrap;
  pointer-events: none;
}

.context-options-wrap-south {
  top: 50%;
}

.context-options {
  transition-duration: 0.25s;
  transition-property: backdrop-filter, background-color, box-shadow, opacity;
  pointer-events: painted;
  z-index: 2;
  display: flex;
  align-content: space-evenly;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.75);
  width: fit-content;
  border-radius: 1em;
  padding: 1em;
  cursor: pointer;
  text-align: center;
  backdrop-filter: blur(4px);
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, .85);
  opacity: 1;
}

.context-options-wrap.hide>* {
  transition-duration: 0.125s;
  transition-property: backdrop-filter, background-color, box-shadow, opacity;
  pointer-events: none;
  opacity: 0;
}

.context-options>span:first-child {
  flex-basis: 100%;
}

.context-options:hover {
  transition-duration: 0.125s;
  transition-property: backdrop-filter, background-color, box-shadow;
  background: rgba(0, 0, 0, 0.90);
  backdrop-filter: blur(8px);
  box-shadow: 0 0 6px 6px rgba(50, 50, 50, .75);
}

.context-options:active {
  transition-duration: 0.125s;
  transition-property: backdrop-filter, background-color, box-shadow;
  background: rgba(10, 10, 10, 0.85);
  backdrop-filter: blur(8px);
  box-shadow: 0 0 4px 4px rgba(50, 50, 50, .5);
}

/*☀|🍁|❄|🍀|⭐*/
.card {
  color: #0000!important;
  background: url('./cardfaces/all.webp');
  background-size: calc(var(--card-width) * 10);
}
.cardface-14-🍁 { background-position: 0 0; }
.cardface-2-🍁  { background-position: calc(-1 * var(--card-width)) 0; }
.cardface-3-🍁  { background-position: calc(-2 * var(--card-width)) 0; }
.cardface-4-🍁  { background-position: calc(-3 * var(--card-width)) 0; }
.cardface-5-🍁  { background-position: calc(-4 * var(--card-width)) 0; }
.cardface-6-🍁  { background-position: calc(-5 * var(--card-width)) 0; }
.cardface-7-🍁  { background-position: calc(-6 * var(--card-width)) 0; }
.cardface-8-🍁  { background-position: calc(-7 * var(--card-width)) 0; }
.cardface-9-🍁  { background-position: calc(-8 * var(--card-width)) 0; }
.cardface-10-🍁 { background-position: calc(-9 * var(--card-width)) 0; }
.cardface-11-🍁 { background-position: 0 calc(-1 * var(--card-height)); }
.cardface-12-🍁 { background-position: calc(-1 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-13-🍁 { background-position: calc(-2 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-14-🍀 { background-position: calc(-3 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-2-🍀  { background-position: calc(-4 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-3-🍀  { background-position: calc(-5 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-4-🍀  { background-position: calc(-6 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-5-🍀  { background-position: calc(-7 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-6-🍀  { background-position: calc(-8 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-7-🍀  { background-position: calc(-9 * var(--card-width)) calc(-1 * var(--card-height)); }
.cardface-8-🍀  { background-position: 0 calc(-2 * var(--card-height)); }
.cardface-9-🍀  { background-position: calc(-1 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-10-🍀 { background-position: calc(-2 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-11-🍀 { background-position: calc(-3 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-12-🍀 { background-position: calc(-4 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-13-🍀 { background-position: calc(-5 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-14-⭐ { background-position: calc(-6 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-2-⭐  { background-position: calc(-7 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-3-⭐  { background-position: calc(-8 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-4-⭐  { background-position: calc(-9 * var(--card-width)) calc(-2 * var(--card-height)); }
.cardface-5-⭐  { background-position: 0 calc(-3 * var(--card-height)); }
.cardface-6-⭐  { background-position: calc(-1 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-7-⭐  { background-position: calc(-2 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-8-⭐  { background-position: calc(-3 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-9-⭐  { background-position: calc(-4 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-10-⭐ { background-position: calc(-5 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-11-⭐ { background-position: calc(-6 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-12-⭐ { background-position: calc(-7 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-13-⭐ { background-position: calc(-8 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-14-☀  { background-position: calc(-9 * var(--card-width)) calc(-3 * var(--card-height)); }
.cardface-2-☀   { background-position: 0 calc(-4 * var(--card-height)); }
.cardface-3-☀   { background-position: calc(-1 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-4-☀   { background-position: calc(-2 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-5-☀   { background-position: calc(-3 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-6-☀   { background-position: calc(-4 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-7-☀   { background-position: calc(-5 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-8-☀   { background-position: calc(-6 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-9-☀   { background-position: calc(-7 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-10-☀  { background-position: calc(-8 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-11-☀  { background-position: calc(-9 * var(--card-width)) calc(-4 * var(--card-height)); }
.cardface-12-☀  { background-position: 0 calc(-5 * var(--card-height)); }
.cardface-13-☀  { background-position: calc(-1 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-14-❄  { background-position: calc(-2 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-2-❄   { background-position: calc(-3 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-3-❄   { background-position: calc(-4 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-4-❄   { background-position: calc(-5 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-5-❄   { background-position: calc(-6 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-6-❄   { background-position: calc(-7 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-7-❄   { background-position: calc(-8 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-8-❄   { background-position: calc(-9 * var(--card-width)) calc(-5 * var(--card-height)); }
.cardface-9-❄   { background-position: 0 calc(-6 * var(--card-height)); }
.cardface-10-❄  { background-position: calc(-1 * var(--card-width)) calc(-6 * var(--card-height)); }
.cardface-11-❄  { background-position: calc(-2 * var(--card-width)) calc(-6 * var(--card-height)); }
.cardface-12-❄  { background-position: calc(-3 * var(--card-width)) calc(-6 * var(--card-height)); }
.cardface-13-❄  { background-position: calc(-4 * var(--card-width)) calc(-6 * var(--card-height)); }

.player-hand .card {
  --card-height: 10em;
  --card-width: calc((var(--card-height) * 410) / 578);
}

.player-collected {
  border: solid #333 1px;
  padding: 1em;
  width: 75%;
  border-radius: 0.5em;
  margin: 1em 0.25em;
  display: flex;
  flex-wrap: wrap;
}

.player-collected::before {
  content: "You've collected:";
  flex-basis: 100%;
}

.player-collected .card {
  font-size: .75em;
  margin: 0;
}